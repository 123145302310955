<template>
  <div class="body container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item v-if="$route.query.tagType">
          <router-link
            :to="{ name: 'tags', query: { tagType: $route.query.tagType } }"
          >
            {{ $t(`pageTitle.${tagTypeDict[$route.query.tagType]}`) }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ thePageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h1>
      {{ thePageTitle }}
      <router-link :to="{ name: 'editTag', params: { tagId } }">
        <i class="fas fa-edit action-icon" />
      </router-link>
    </h1>
    <el-table :data="attachAddButton(tagUsers)">
      <el-table-column :label="$t('column.user')">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.type === 'addItem'"
            type="primary"
            size="mini"
            @click="addUser"
          >
            {{ $t("tag.form.addUser") }}
          </el-button>
          <div v-else>
            <el-select
              filterable
              v-if="scope.row.edit"
              :value="scope.row.newUserId || scope.row.id"
              @change="
                userId => {
                  setUser(scope.$index, scope.row.id, userId);
                }
              "
            >
              <el-option
                :key="user.id"
                v-for="user in users"
                :label="`${user.first_name} ${user.last_name}`"
                :value="user.id"
              >
                {{
                  `${userMethods.displayName(
                    user.first_name,
                    user.last_name
                  )} (${user.email})`
                }}
              </el-option>
            </el-select>
            <span v-else>
              {{
                `${userMethods.displayName(
                  scope.row.first_name,
                  scope.row.last_name
                )} (${scope.row.email})`
              }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('column.action')">
        <template slot-scope="scope">
          <div v-if="scope.row.type !== 'addItem'">
            <div v-if="scope.row.edit">
              <el-tooltip
                :value="true"
                :content="$t('message.remeberSave')"
                placement="top"
                popper-class="tooltipColor"
              >
                <span
                  key="check"
                  class="action-icon"
                  @click="attachTagsToUser(scope.row)"
                >
                  <i class="fas fa-check" />
                </span>
              </el-tooltip>
              <span
                key="times"
                class="warning-icon"
                @click="closeEditMode(scope.row, scope.$index)"
              >
                <i class="fas fa-times" />
              </span>
            </div>
            <div v-else>
              <span class="action-icon" @click="openEditMode(scope.row.id)">
                <i class="fas fa-edit" />
              </span>
              <span
                class="warning-icon"
                @click="detachTagsToUser(scope.row.id)"
              >
                <i class="fas fa-trash-alt" />
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { helper, user } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import roleMixin from "@/mixins/role";
import usersApi from "@/apis/users";
import userApi from "@/apis/user";
import tagApi from "@/apis/tag";
import Tag from "@/views/tags/Tag";
import tagTypeDict from "./tagTypeDict";

export default {
  metaInfo() {
    return {
      title: `${this.thePageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb
  },
  mixins: [roleMixin],
  computed: {
    userMethods() {
      return user;
    },
    helper() {
      return helper;
    },
    thePageTitle() {
      return this.helper.displayI18nText(this.$i18n.locale, this.tag.name);
    },
    tagId() {
      return this.$route.params.tagId;
    },
    tagTypeDict() {
      return tagTypeDict;
    }
  },
  data() {
    return {
      tagUsers: [],
      users: [],
      tag: new Tag()
    };
  },
  async created() {
    this.initialTag();
    if (this.$route.query.tagType === "user") {
      this.users = await usersApi.getActiveUsers();
    } else {
      this.users = await usersApi.getActiveUsers(this.$route.query.tagType);
    }
  },
  methods: {
    async initialTag() {
      const { tag, users } = await tagApi.fetchTag(this.tagId);
      this.tag = new Tag({ name: tag.name });
      this.tagUsers = users;
    },
    attachAddButton(users) {
      if (!this.isRoleAdmin()) return users;
      return [...users, { type: "addItem" }];
    },
    openEditMode(userId) {
      this.tagUsers = this.tagUsers.map(tagUser => {
        if (tagUser.id !== userId) return tagUser;
        return { ...tagUser, edit: true };
      });
    },
    closeEditMode(targetUser, targetIndex) {
      if (!targetUser.first_name) {
        this.tagUsers = this.tagUsers.filter(
          (tagUser, index) => index !== targetIndex
        );
      } else {
        this.tagUsers = this.tagUsers.map(tagUser => {
          if (tagUser.id !== targetUser.id) return tagUser;
          return { ...tagUser, newUserId: targetUser.id, edit: false };
        });
      }
    },
    addUser() {
      this.tagUsers = [...this.tagUsers, { user_id: null, edit: true }];
    },
    setUser(targetIndex, originUserId, newUserId) {
      if (originUserId === newUserId) return;
      this.tagUsers = this.tagUsers.map((user, index) => {
        if (index !== targetIndex) return user;
        return {
          ...user,
          newUserId
        };
      });
    },
    isExistUser(newUser) {
      const userIndex = this.tagUsers
        .filter(tagUser => !tagUser.edit)
        .findIndex(tagUser => tagUser.id === newUser.newUserId);
      if (userIndex === -1) return false;
      this.$message.error(this.$t("tag.form.theUserAlreadyExist"));
      return true;
    },
    async attachTagsToUser(user) {
      if (this.isExistUser(user)) return;
      if (user.newUserId === undefined || user.newUserId === user.id) {
        this.closeEditMode(user);
        return;
      }
      user.id && (await this.detachTagsToUser(user.id));
      await userApi.attachTagsToUser(user.newUserId, [this.tagId]);
      this.initialTag();
    },
    async detachTagsToUser(userId) {
      await userApi.detachTagsToUser(userId, [this.tagId]);
      this.initialTag();
    }
  }
};
</script>
